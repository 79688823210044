<template>
  <div>
     <b-row>
      <b-colxx md="6" xxs="12">
        <b-card v-if="banderaRegistrado">
          <section id="msgGracias">
            <h1>¡Muchas gracias por confiar en SoLe!</h1>
            <p>Ya podrás visualizar tu dispositivo con serial <strong>{{serialRegistrado}}</strong></p>
            <h3>Si tienes alguna duda contáctanos</h3>
            <p><a href="https://api.whatsapp.com/send?phone=5213331015825"><img src="/static/img/WhatsApp_Logo_3.svg" id="whatsappLogo"> 33-3101-5825</a> o en nuestro <a href="/asistencia">Formulario de contacto</a></p>
            <hr>
            <img id="logoGracias" src="/static/img/logo-sole.svg">
          </section>
        </b-card>
        <b-card v-else>
          <h2>Estás a un paso de utilizar tu nuevo dispositivo SoLe-Tracking</h2>
          <p>Solo debes ingresar en el formulario el <strong>número serial</strong> y  <strong>llave de activación</strong> que vienen dentro del empaque de tu dispositivo</p>
          <p>Si tienes cualquier duda con este proceso no dudes en contactarnos</p>
          <p><a href="https://api.whatsapp.com/send?phone=5213331015825"><img src="/static/img/WhatsApp_Logo_3.svg" id="whatsappLogo"> 33-3101-5825</a> o en nuestro <a href="/asistencia">Formulario de contacto</a></p>
        </b-card>
      </b-colxx>
      
      <b-colxx md="6" xxs="12">
          <b-card class="mb-4" :title="$t('Ingrese la información de su dispositivo nuevo')">
            <b-form >
              <b-form-group class="col-md-12">
                <b-form-input type="text" v-model="registroDispositivo.serial" :placeholder="$t('Número serial de dispositivo')"/>
              </b-form-group>
              <b-form-group class="col-md-12">
                <b-form-input type="text" v-model="registroDispositivo.dvKey" :placeholder="$t('Llave de activación')"/>
              </b-form-group>
              <span v-if="error.status" id="formError">{{error.message}}</span>
              <b-button type="button" variant="primary" v-on:click="registrarDispositivo()"class="mt-4">Activar</b-button>
            </b-form>
          </b-card>
      </b-colxx>
      
    </b-row>
  </div>
</template>
<script>
import { requests , mapActions } from 'vuex'

  export default{
    data(){
      return {
        newFolio: "",
        error: {
          status : false,
          message: ""
        },
        registroDispositivo:{
          dvKey: "",
          serial:""
        },
        banderaRegistrado: false,
        serialRegistrado: ""
      }
    },
    methods: {
      ...mapActions(["getAll","create","update"]),

      registrarDispositivo: function(){
        var instance = this;
        var token = JSON.parse(localStorage.getItem('user')).token;
            //let promise = axios.get("/api/usuario");
        let payload = {
          endpoint: "/api/dispositivo/registrar-dvkey",
          token: token,
          data: instance.registroDispositivo
        };

        instance.$parent.toggleLoader("Registrando dispositivo");

        this.create(payload).then((response) => {
          if(response.status === 200){
            instance.serialRegistrado = instance.registroDispositivo.serial;
            
            instance.$toasted.success("Su dispositivo ha sido dado de alta correctamente");
            instance.registroDispositivo = {
              dvKey: "",
              serial: ""
            };
            instance.banderaRegistrado = true;  
          }else{
            instance.$toasted.error(response.error);
          }

          instance.$parent.toggleLoader();
          
        }).catch(_error => {
         instance.$parent.toggleLoader();
         instance.$toasted.error(error.msg);
        });
      }
    }
  }
</script>
<style>

  #whatsappLogo{
    width: 30px;
  }

  #msgGracias{
    padding: 2em;
    margin: 2em;
    border: 2px solid rgba(72, 189, 19, 0.7);
    background-color: rgba(72,189,19,0.1);
    text-align: center;
    font-size: 1.5em;
  }

  #logoGracias{
    width: 20vw;
  }

</style>